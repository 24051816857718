import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';

const Header = () => (
	<header class={style.header}>
		<a href="/" class={style.logo}>
			<img src="../../assets/CADEMIS-LOGO.png" alt="Cademis Logo" height="32" width="32" />
			<small style="margin-left: 1rem">CADEMIS</small>
		</a>
	</header>
);

export default Header;
