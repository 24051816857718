import { h } from "preact";
import { Router } from "preact-router";
import Header from "./header";

// Code-splitting is automated for `routes` directory
import Lawyers from "../routes/lawyers";
import ValidateQr from "../routes/validate-qr";


const App = () => (
  <div id="app">
    <Header />
    <main>
      <Router>
        <Lawyers path="/" />
        <ValidateQr path="/qr/:code" />
      </Router>
    </main>
  </div>
);

export default App;
